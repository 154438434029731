import "./App.css";
import CategoryItems from "./Components/CategoryItems";
import NavBar from "./Components/NavBar";
import ProductItems from "./Components/ProductItems";
import React, { useState } from "react";
// TEMP images import
import productOne from "./Assets/Products/productOne.jpg";
import productTwo from "./Assets/Products/productTwo.jpg";
import productThree from "./Assets/Products/productThree.jpg";
import productFour from "./Assets/Products/productFour.jpg";
import productFive from "./Assets/Products/productFive.jpg";
import productSix from "./Assets/Products/productSix.jpg";
import productSeven from "./Assets/Products/productSeven.jpg";
import productEight from "./Assets/Products/productEight.jpg";
import AboutFirst from './Assets/AboutFirst.jpg'
import AboutSecond from './Assets/AboutSecond.jpg'
import RecipeOne from './Assets/RecipeOne.jpg'
import RecipeTwo from './Assets/RecipeTwo.jpg'
import RecipeThree from './Assets/RecipeThree.jpg'

import SlideHover from "./Components/RightSlider/SlideHover";
import InformationBlock from "./Components/InformationBlock";
import RecipeItems from "./Components/Recipes/RecipeItems";
import FooterItems from "./Components/Footer/FooterItems";

function App() {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "COP",
  });

  const [openSlider, setOpenSlider] = useState(false);
  const INITIAL_PRODUCTS = [
    {
      name: "MILANESA ESPECIAL",
      category: "carne",
      price: 25900,
      image: productEight,
      alt: "productEight",

    },
    {
      name: "MUCHACHO",
      category: "carne",
      price: 25900,
      image: productTwo,
      alt: "productTwo",
    },
    {
      name: "COSTILLA ESPECIAL",
      category: "carne",
      price: 14000,
      image: productThree,
      alt: "productThree",
    },
    {
      name: "LOMO ANCHO",
      category: "carne",
      price: 29900,
      image: productFour,
      alt: "productFour",
    },
    {
      name: "LOMO FINO",
      category: "carne",
      price: 35900,
      image: productFive,
      alt: "productFive",
    },
    {
      name: "LOMO DE CERDO",
      category: "cerdo",
      price: 19000,
      image: productSix,
      alt: "productSix",
    },
    {
      name: "CHULETA DE CERDO",
      category: "cerdo",
      price: 15000,
      image: productSeven,
      alt: "productSeven",
    },
    {
      name: "CHORIZO PREMIUN",
      category: "charcuteria",
      price: 20400,
      image: productOne,
      alt: "productOne",
    },
  ];

  const RECIPE_ITEMS = [
    {title: "Steak Pimienta", month: "AGO", day:"19", image:RecipeOne},
    {title: "Cerdo Agridulce", month: "FEB", day:"03", image:RecipeTwo },
    {title: "Carne al Carbón", month: "SEP", day:"30", image:RecipeThree },
  ]

  const [cartItems, setCartItems] = useState([]);
  const [totalPayment, setTotalPayment] = useState(0);

  const removeItemCartHandler = (itemId) => {
    let totalProducts = [];
    setCartItems((prev) => {
      
      totalProducts = prev.filter((product) => {
        return product.id !== itemId;
      });
      updateTotalPayment(totalProducts);
      return totalProducts;
    });

    
  };
  const addItemCartHandler = (item) => {
    setCartItems((prev) => {
      const totalProducts = [
        ...prev.filter((product) => {
          return product.id !== item.id;
        }),
        item,
      ];
      updateTotalPayment(totalProducts);
      return totalProducts;
    });
  };

  const updateSliderStateHandler = (event) => {
    setOpenSlider((prev) => {
      return !prev;
    });
  };

  const updateTotalPayment = (totalProducts) => {
    let totalVal = 0;
    totalProducts.forEach((element) => {
      totalVal += element.total;
    });
    setTotalPayment(totalVal);
  };

  return (
    <>
      <div className="intro">
        <NavBar openSlider={updateSliderStateHandler} />
        <div className="text-frame">
          <h1> Expertos en Carnes</h1>
          <h3>Tradición Familiar desde 1975</h3>
        </div>
      </div>

      <CategoryItems />
      <ProductItems
        formatter={formatter}
        addItemToCart={addItemCartHandler}
        products={INITIAL_PRODUCTS}
      />
      <SlideHover
        removeItem={removeItemCartHandler}
        openSlider={openSlider}
        setOpenSlider={updateSliderStateHandler}
        formaterHandler={formatter}
        cartProducts={cartItems}
        total={formatter.format(totalPayment)}
      />


      <InformationBlock title={"NOSOTROS"} img={AboutFirst} info={"Somos la unión de esfuerzos, sueños y propósitos, que comparten y lidera una familia de arraigo campesina, que trabaja cada día para impulsar y promover el desarrollo, el cambio social, ambiental y productivo, dentro de un marco de ética, eco-eficiencia y de responsabilidad social, que contribuya a mejorar la calidad de vida de las personas."}/>
      <InformationBlock reverse={true} title={"UNA PROMESA"} img={AboutSecond} boldpart={"Nutrir bien a los colombianos"} info={", esa es y será nuestra razón de ser como empresa productora de alimentos para el consumo humano. "}/>
      
      <RecipeItems items={RECIPE_ITEMS}/>

      <FooterItems items={RECIPE_ITEMS}/>

    </>
  );
}

export default App;
