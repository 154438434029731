import classes from './ButtonUpdateQuantity.module.css'

const ButtonUpdateQuantity = (props) => {

    const updateAmountHandler = (event) => {
        props.updateAmount((prev) => {return prev + props.amount})
    }

    return <button className={`${props.className} ${classes['button']}`} type="button" onClick={updateAmountHandler}> 
        {props.children}
    </button>

}

export default ButtonUpdateQuantity;