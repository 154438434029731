import classes from "./Recipes.module.css";

const Recipe = (props) => {
  return (
    <div
      className={classes["frame-card"]}
      style={{ background: `url(${props.image})` }}
    >
      <div className={classes["date-card"]}>
        <h3 className={classes['card-color']}>{props.day}</h3>
        <div className={`${classes['card-color']} label-large`}>{props.month}</div>
      </div>
      <div className="headline-small">{props.title}</div>
    </div>
  );
};

export default Recipe;
