import CategoryButton from "../UI/CategoryButton";
import CategoryOne from "../Assets/CategoryOne.jpg"
import CategoryTwo from "../Assets/CategoryTwo.jpg"
import CategoryThree from "../Assets/CategoryThree.jpg"
import classes from './CategoryItems.module.css'

const CategoryItems = (props) => {
    return (<div>
        <div className={classes["title-category"]}>
        <h3 > CATEGORIAS </h3>
        </div>
        
        <div className={classes['items-card']}>
            <CategoryButton type="button" title="Carne" image={CategoryTwo}/>
            <CategoryButton type="button" title="Charcuteria" image={CategoryOne}/>
            <CategoryButton type="button" title="Cerdo" image={CategoryThree}/>
        </div>
    </div>)

}

export default CategoryItems;