import ButtonsItems from "../UI/ButtonsItems";
import ButtonSubmit from "../UI/ButtonSubmit";
import classes from "./Product.module.css";
import React, { useState } from "react";

const Product = (props) => {
  const [productAmount, setProductAmount] = useState(0);

  const addProductCartHandler = (event) => {
    event.preventDefault();
    let productItem = {
        id: props.id,
      name: props.name,
      amount: productAmount,
      total: +productAmount * +props.price,
    };
    props.addProductToCart(productItem);
    setProductAmount(0);
  };

  return (
    <div className={classes["product-card"]}>
      <div>
        <img className={classes['product-image']} src={props.image} alt={props.alt} />
      </div>
      <div>
        <label className="label-large">{props.name}</label>
        <p>{props.category}</p>
        <p>{props.formatHandler.format(props.price)}</p>
      </div>
      <div>
        <ButtonsItems
          amountItems={productAmount}
          setAmountItems={setProductAmount}
        />
        <ButtonSubmit className="label-small" onClick={addProductCartHandler}>
          {" "}
          Añadir al Carrito
        </ButtonSubmit>
      </div>
    </div>
  );
};

export default Product;
