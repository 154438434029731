import classes from "./ProductCart.module.css";
import ImageProduct from "../../Assets/cartElementPhoto.jpg";

const ProductCart = (props) => {

  const removeThisItemHandler = () => {
    props.removeThisItem(props.id)
  }
  return (
    <div className={classes["card-element"]}>
      <div className={"image-product"}>
        <img src={ImageProduct} alt="imagen del producto" />
      </div>

      <div className={classes["info-product"]}>
        <div className={"title-medium"}>{props.name} </div>
        <div className={"label-small"}>
          {props.formaterHandler.format(props.subtotal)}
        </div>
      </div>

      <button onClick={removeThisItemHandler} className={classes['close-btn']}>
        x
      </button>
    </div>
  );
};

export default ProductCart;
