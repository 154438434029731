import styles from './RecipeButton.module.css'

const RecipeButton = (props) => {
    return <button className={styles['frame-button']} onClick={props.onClick}>
        <img alt="receta" className={styles['image-recipe']} src={props.img}/>
        <div className={styles['information-btn']}>
            <div className="title-large">{props.title} </div>
            <div className={`${styles['date-text']} title-small`}> {props.date}</div>
        </div>
    </button>

}

export default RecipeButton;