import ButtonSubmit from "../../UI/ButtonSubmit";
import ProductCart from "./ProductCart";
import classes from "./SlideHover.module.css";


const SlideHover = (props) => {

  return (
    <div
      className={`${classes["cart-frame"]}  ${
        props.openSlider ? "" : classes["hide-slider"]
      }`}
    >
      <h3>CARRITO DE COMPRAS</h3>

      <div>

        {props.cartProducts.map((product) => {
          return (
            <ProductCart
            key={product.id}
            removeThisItem={props.removeItem}
            id={product.id}
          formaterHandler={props.formaterHandler}
          name={product.name}
          subtotal={product.total}
        />
          );
        })}
      </div>

      <div className={classes["button-items"]}>
        <div className={classes["container-subtotal"]}>
          <p>SUBTOTAL: </p>
          <p>{props.total}</p>
        </div>

        <ButtonSubmit
          onClick={props.setOpenSlider}
          className={`${classes["button-finish"]} label-large`}
        >
          CERRAR
        </ButtonSubmit>
        <ButtonSubmit
          className={`${classes["button-close"]} label-large ${classes["button-finish"]}`}
        >
          FINALIZAR COMPRA
        </ButtonSubmit>
      </div>
    </div>
  );
};

export default SlideHover;
