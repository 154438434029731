import classes from './NavBar.module.css'
import { ReactComponent as LogoCompany } from '../Assets/logo_improved.svg'
import { ReactComponent as PersonLogo } from '../Assets/person.svg'
import { ReactComponent as CartLogo } from '../Assets/cart.svg'
import React, {useState} from 'react';

const NavBar = (props) => {
    const [openMenu, setOpenMenu] = useState(false)

    const updateMenuHandler = () => {
        setOpenMenu((prev) => {
            return !prev
        })
    }

    return (<div>
<div className={classes.navBar}>
        <nav className={classes['navBar-menu']}>
            <a href="#"> INICIO</a>
            <a href="#"> PRODUCTOS </a>
            <a href="#"> OFERTAS </a>
            <button className={classes["logo-button"]} onClick={updateMenuHandler}>
                <LogoCompany  className={classes['main-logo']}/> 
            </button>
           
            <a href="#"> RECETAS</a>
            <a href="#"> CONTACTOS </a>
            <a href="#"> NOSOTROS </a> 
        </nav>



        <div className={classes['navBar-market']}>
            <PersonLogo/>
            <p> INICIAR SESIÓN</p>
            <button className={classes['button-cart']}onClick={props.openSlider}>
            <CartLogo/>
            </button>
            
            <p> $0</p>

        </div>

  

    </div>
    <nav className={`${openMenu ? classes['navBar-menu-small']: classes['hide-navBar']} `}>
            <a href="#"> INICIO</a>
            <a href="#"> PRODUCTOS </a>
            <a href="#"> OFERTAS </a>
            <a href="#"> RECETAS</a>
            <a href="#"> CONTACTOS </a>
            <a href="#"> NOSOTROS </a> 
        </nav>
    </div>);
    
}


export default NavBar;