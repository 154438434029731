import classes from './InformationBlock.module.css'

const InformationBlock = (props) => {

        return (
            <div className={`${classes['main-frame']} ${props.reverse && classes['reverse-row']}`}>
             <img className={classes['image']} src={props.img} alt="Imagen de nuestra finca"/>
     
             <div className={classes['information-section']}>
                 <h3>{props.title}</h3>
                 <p><b>{props.boldpart}</b>{props.info}</p>
             </div>
     
            </div>
         );

}

export default InformationBlock;