import classes from "./CategoryButton.module.css";

const CategoryButton = (props) => {
  return (
    <button
      className={`${props.className} ${classes.button}`}
      type={props.type}
      onClick={props.onClick}
      style={{ background: `url(${props.image})` }}
    >
        <h4>{props.title}</h4>
      
    </button>
  );
};

export default CategoryButton;
