import Recipe from './Recipe'
import classes from './RecipeItems.module.css'

const RecipeItems = (props) => {

    return <div className={classes['module-recipe']}>
        <h3> RECETAS </h3>
        <div  className={classes['recipe-organizer']}>
        {props.items.map((recipe) => {
            return <Recipe title={recipe.title} month={recipe.month} day={recipe.day} image={recipe.image} />
        })}
        </div>
      
    </div>

}

export default RecipeItems;