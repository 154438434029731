import classes from "./ButtonSubmit.module.css";

const ButtonSubmit = (props) => {
  return (
    <button
      onClick={props.onClick}
      type={props.type}
      className={`${props.className} ${classes["button"]}`}
    >
      {props.children}
    </button>
  );
};

export default ButtonSubmit;
