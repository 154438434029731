import styles from "./FooterItems.module.css";
import { ReactComponent as LogoCompany } from "./../../Assets/logo_improved.svg";
import RecipeButton from "./RecipeButton";
const FooterItems = (props) => {
  return (
    <div className={styles["main-frame"]}>
      <div className={ `${styles["general-info"]} body-large`}>
        <LogoCompany />
        <div>
          Expertos en carnes, tradición familiar desde 1975 <br/> carnes maduradas y charcuteria
        </div>
        <ul>
          <li>Loma Del Bálsamo, Algarrobo, Magdalena</li>
          <li>WhatsApp: 302 494 0504</li>
          <li>info@carnesibarra.com</li>
        </ul>
      </div>
      <div className={styles["recipes-info"]}>
        <div className="title-large"> RECETAS RECIENTES </div>
        {props.items.map((recipe) => {
            return <RecipeButton title={recipe.title} img={recipe.image} date={`${recipe.month}/${recipe.day}`}/>
        })}
      </div>
      <div className={styles["location-info"]}>
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3925.5511997225135!2d-74.03933339999999!3d10.297698699999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8ef57de169d9b021%3A0xb785ff0edf580957!2sCarnes%20Ibarra!5e0!3m2!1ses!2sco!4v1661440148461!5m2!1ses!2sco"
          width="378"
          height="308"
          style={{ border: "0" }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default FooterItems;
