import Product from "./Product";
import classes from "./ProductItems.module.css";

const ProductItems = (props) => {


  return (
    <div className={classes["product-frame"]}>
      <div>
        <h3>PRODUCTOS</h3>
      </div>

      <div className={classes["product-items-frame"]}>
        {props.products.map((product) => {
          return (
            <Product
              formatHandler={props.formatter}
              addProductToCart={props.addItemToCart}
              key={product.alt}
              name={product.name}
              category={product.category}
              price={product.price}
              image={product.image}
              id={product.alt}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ProductItems;
