import ButtonUpdateQuantity from "./ButtonUpdateQuantity";
import classes from "./ButtonsItems.module.css";

const ButtonsItems = (props) => {

  return (
  
      <div className={classes["items-btn"]}>
        <ButtonUpdateQuantity
          amount={-1}
          updateAmount={props.setAmountItems}
          className={classes["subtract-btn"]}
        >
          {" "}
          -{" "}
        </ButtonUpdateQuantity>
        <div className={`${classes["current-amount-display"]} label-small`}>
          {props.amountItems}
        </div>
        <ButtonUpdateQuantity
          amount={1}
          updateAmount={props.setAmountItems}
          className={classes["add-btn"]}
        >
          {" "}
          +{" "}
        </ButtonUpdateQuantity>
      </div>

     
  );
};

export default ButtonsItems;
